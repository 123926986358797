import styled from 'styled-components'

import { theme } from 'styles/theme'

const { fontSizes, media, space } = theme

export const Statistics = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 17.25rem;
  gap: ${space['spacing-xxs']};

  p.statistics-text {
    width: 14rem;
  }

  @media (${media.tablet}) {
    height: 36.688rem;
    gap: ${space['spacing-md']};

    p.statistics-number {
      font-size: ${fontSizes['font-size-heading-1']};
    }
    
    p.statistics-text {
      width: 17rem;
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -${space['spacing-xxl']};
  bottom: 0;
  width: 100%;
  min-width: 40rem;
  overflow: hidden;

  @media (${media.tablet}) {
    inset: 0 0 auto auto;
    width: 85.375rem;
  }
`

export const LinearGradient = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(234.06deg, rgba(235 235 235 / 0%) 15.56%, #fafafa 93.58%);

  @media (${media.tablet}) {
    inset: 0 0 auto auto;
    width: 85.375rem;
    background: linear-gradient(90deg, rgba(255 255 255 / 1%) 18%, rgba(71 69 69 / 0%) 45%, rgba(71 69 69 / 0%) 76%);
  }
`
